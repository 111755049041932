.submit-container {
  display: flex;
  justify-content: flex-end;
}

ion-item.input-container {
  --padding-start: 0;
  --inner-padding-end: 0;
  --background-activated: transparent;
  --background-hover: transparent;
  --ripple-color: transparent;

  font: var(--tss-body-medium);
  // font: 400 14px 'Lato';
  margin-bottom: 20px;

  ion-textarea {
    .label-text-wrapper {
      font: var(--tss-label-medium);

      // Do not automatically scale labels down by 75%
      transform: none !important;
    }
  }

  &.item-label-stacked {
    --padding-start: 0;
    --inner-padding-end: 0;
    --background-activated: transparent;
    --background-hover: transparent;
    --ripple-color: transparent;
  }
}

ion-item.radio-container {
  --padding-start: 0;
  --inner-padding-end: 0;
  --background-activated: transparent;
  --background-hover: transparent;
  --ripple-color: transparent;

  font-size: calc(14 / 16 * 1rem);
}

.work-order-search-container {
  display: flex;
  justify-content: space-between;

  margin-top: 32px;

  .searchbar {
    padding: 0px;

    --background: #ececec;
    .searchbar-input-container {
      height: 52px;
      border: none;
      border-width: 0;
    }
    .searchbar-input {
      font-size: 14px;
      height: 52px;
      padding-right: 4px;
      box-shadow: none;
      border-radius: 10px;
    }
  }
}

.no-shadow {
  --box-shadow: none;
}

ion-note.input-error {
  font-size: calc(12 / 16 * 1rem);
  margin-top: 4px;
}

ion-radio::part(container) {
  border-radius: 50%;
  border: 1px solid var(--tss-grey-lightest);
  height: 30px;
  width: 30px;
}

ion-radio::part(mark) {
  background: none;
  transition: none;
  transform: none;
  border-radius: 0;
}

ion-radio.radio-checked::part(container) {
  background: var(--tss-green);
  border-color: transparent;
}

ion-radio.radio-checked::part(mark) {
  width: 6px;
  height: 12px;

  border-width: 0px 1.5px 1.5px 0px;
  border-style: solid;
  border-color: var(--tss-white);

  transform: rotate(45deg);
}
