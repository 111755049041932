.trip-preview-container {
  ion-chip {
    margin-top: 0;
  }
}

.input-margin {
  margin-bottom: 20px;
}

.text-area {
  border-bottom: 1px solid var(--ion-border-color);
  margin-top: 10px;
}
