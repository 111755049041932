.geo-doc-img {
  width: 200px;
  margin-bottom: 20px;
  border: 2px solid;

  &.geo-doc-img-wider {
    width: 500px;
  }
}

.geo-header {
  text-decoration: underline;
  margin-bottom: 20px;
}

.iphone-docs-divide {
  height: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}
