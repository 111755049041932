// .work-order-preview-content {
//   p {
//     // font: var(--tss-body-medium);
//   }
// }

.underline {
  text-decoration: underline;
}

.card-styling-wo {
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

  &.accordion-expanded {
    padding-bottom: 20px;
  }
}

.trip-form-container {
  margin-top: 30px;
}

.section-container {
  margin-bottom: 20px;

  h4 {
    margin-bottom: 8px;
  }

  .body-medium {
    margin: 0px;
    line-height: 22px;
  }
}

.list-group {
  ul {
    li {
      line-height: 22px;
    }
  }
}

.thumbnail {
  width: 150px;
}

.image-form {
  padding: 20px;
  border: #616161;
  border-style: dashed;
  border-radius: 10px;
}

.geo-doc-link {
  font-size: $s-base;
  float: right;
  text-decoration: underline;
}

.geo-doc-info-img {
  vertical-align: bottom;
  margin-left: 4px;
}

.geo-doc-alert-img {
  margin-right: 4px;
}

.geo-warning-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  color: var(--tss-green);
  max-width: 350px;
  font-size: $s-base;
  height: 32px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: -15px;
  margin-top: 15px;
}

.geo-warning-container strong {
  margin-left: 4px;
  margin-right: 4px;
}

.geo-warning-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--tss-green);
  opacity: 0.1; /* 10% opacity */
  z-index: -1; /* Ensure the background is behind the text */
}
