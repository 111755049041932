// Base Typography
// ========================================

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  @include font-type($primary-sans-serif, normal, normal);
  color: var(--tss-text);
  font: 100%/1.618;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 8px;
  margin-top: 30px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  strong,
  b {
    font-weight: $semibold;
  }
}

h1,
h1 a {
  font-size: 26px;
  font-weight: $bold;
}

h2,
h2 a {
  font-size: calc(20 / 16 * 1rem);
  font-weight: $bold;
}

h3,
h3 a {
  font-size: 1rem;
  font-weight: $semibold;
}

h4,
h4 a {
  font-size: calc(14 / 16 * 1rem);
  font-weight: $semibold;
  margin-top: 12px;
}

.subheader h1 {
  font-size: $s-large;
  font-weight: $semibold;
  color: var(--tss-text);

  @media (min-width: $tablet) {
    font-size: $s-huge;
  }
}

h5,
h5 a {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: $semibold;
  margin-bottom: 20px;
}

.subheader h2 {
  font-size: 16px !important;
  text-transform: uppercase;
  font-weight: $semibold !important;
  margin-bottom: 20px;
}

h6,
h6 a {
  font-size: 16px;
  color: #5c5d61;
  text-transform: uppercase;
  font-weight: $bold;
  margin-bottom: 20px;
}

.subheader h3 {
  font-size: 16px !important;
  color: #5c5d61;
  text-transform: uppercase;
  font-weight: $bold;
  margin-bottom: 20px;
}

p {
  color: var(--tss-text);
  font-weight: normal;
  font-style: normal;
  font-size: calc(14 / 16 * 1rem);
  @include font-type($secondary-sans-serif, normal, $regular);
  margin: 0;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

li,
dd {
  color: var(--tss-text);
  @include font-type($secondary-sans-serif, normal, $light);
  @include s-medium;
  line-height: $base-line-height;
  list-style: none;
}

a {
  color: var(--tss-text);
  cursor: pointer;
  @include s-small;
  @include font-type($primary-sans-serif, normal, $medium);
  transition: all 0.5s ease-in-out;
  text-decoration: none;

  @media (min-width: $tablet) {
    @include s-medium;
  }

  &:hover,
  &:focus {
    color: var(--tss-text);
    text-decoration: underline;
  }
}

strong,
b {
  font-weight: $bold;
}
i,
em {
  font-style: italic;
}

u {
  text-decoration: underline;
}

form ion-label {
  font-size: 1rem;
  font-weight: $semibold;
  letter-spacing: 0.25px;

  // By default, Ionic transforms the scale of a stacked label by 0.75.
  // Override this scaling by removing it.
  &.stacked-label {
    -webkit-transform: scale(1) !important;
    transform: none !important;
  }
}

.title-large {
  font-size: 28px;
  font-weight: 600;
}
.title-medium {
  font-size: 24px;
  font-weight: 600;
}

.title-small {
  font-size: 20px;
  font-weight: 600;
}

.title-extra-small {
  font-size: 16px;
  font-weight: 600;
}

.body-extra-large {
  font-size: calc(20 / 16 * 1rem);
  font-weight: 700;
  margin-top: 8px;
}

.body-large {
  font-size: 16px;
  font-weight: 400;
}

.body-medium {
  font-size: 14px;
  font-weight: 400;
}

.body-small {
  font-size: 12px;
  font-weight: 400;
}

.label-small {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.25px;
}

.label-medium {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.25px;
}

.label-large {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.25px;
}

b {
  font-size: 16px;
  text-decoration: underline;
  font-weight: 500;
}
