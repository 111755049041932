// this files generates a number of convenient css classes:
//    - padding-X 
//    - padding-DIRECTION-X
//    - padding-x-DIRECTION-X
//    - padding-y-DIRECTION-X
//    - margin-X
//    - margin-DIRECTION-X

$range: 200;
$pixel_increment: 1;

@mixin convenience-list {
  @for $i from 0 through $range {
      .padding-#{$i} { padding: #{($i * $pixel_increment)}px;}
      .padding-bottom-#{$i} { padding-bottom: #{($i * $pixel_increment)}px;}
      .padding-top-#{$i} { padding-top: #{($i * $pixel_increment)}px;}
      .padding-left-#{$i} { padding-left: #{($i * $pixel_increment)}px;}
      .padding-right-#{$i} { padding-right: #{($i * $pixel_increment)}px;}
      .padding-x-#{$i} { padding-right: #{($i * $pixel_increment)}px; padding-left: #{($i * $pixel_increment)}px;}
      .padding-y-#{$i} { padding-top: #{($i * $pixel_increment)}px; padding-bottom: #{($i * $pixel_increment)}px;}
      .margin-#{$i} { margin: #{($i * $pixel_increment)}px;}
      .margin-bottom-#{$i} { margin-bottom: #{($i * $pixel_increment)}px;}
      .margin-top-#{$i} { margin-top: #{($i * $pixel_increment)}px;}
      .margin-left-#{$i} { margin-left: #{($i * $pixel_increment)}px;}
      .margin-right-#{$i} { margin-right: #{($i * $pixel_increment)}px;}
      .margin-x-#{$i} { margin-right: #{($i * $pixel_increment)}px; margin-left: #{($i * $pixel_increment)}px;}
      .margin-y-#{$i} { margin-top: #{($i * $pixel_increment)}px; margin-bottom: #{($i * $pixel_increment)}px;}
  }
}

@include convenience-list;