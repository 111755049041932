ion-accordion-group {
  width: 100%;

  ion-accordion {
    background-color: initial;

    ion-item {
      --padding-start: 0;
      --inner-padding-end: 0;
      --min-height: auto;

      ion-label {
        margin: 0;
      }

      button.item-native {
        color: green;

        .item-inner {
          padding-inline-end: 0;
        }
      }
    }
  }
}
