ion-item.fileinput-container {
  .description {
    color: var(--ion-color-primary);
    margin: 0;
    margin-bottom: 12px;
  }

  ion-list.all-fileinput-previews-container {
    width: 100%;

    ion-item.fileinput-preview-container {
      --padding-start: 0;
      --inner-padding-end: 0;

      font-size: calc(14 / 16 * 1rem);
      margin-bottom: 20px;;
      width: 100%;

      ion-icon {
        color: var(--tss-text);
      }

      ion-button {
        margin: 0;
      }
    }
  }

  .native-fileinput-container {
    position: relative;

    border: 1px dashed var(--tss-grey-light);
    border-radius: 10px;
    display: relative;
    height: 62px;
    width: 100%;

    input[type='file'] {
      opacity: 0;
      width: 100%;

      &:hover {
        cursor: pointer;
      }
    }

    ion-spinner, ion-icon {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      top: 0px;

      color: var(--tss-grey-light);
      margin: auto;
    }

    &.changing {
      border-color: var(--tss-grey-lightest);
    }
  }
}
