.image-upload {
  fieldset {
    margin-left: auto;
    margin-right: auto;
  }
  .fileupload {
    margin: 0 auto;
  }

  label {
    display: none;
  }
}

input::file-selector-button {
  background: white;
  border-style: solid;
  border-color: #202020;
  border-width: 1px;
  padding: 1em;
  border-radius: 30px;
  padding: 15px;
  margin-right: 20px;
  font-family: lato;
}

.ml-auto {
  ion-button {
    border-style: none;
    align-items: center;
    align-content: center;
  }
}

#ion-react-wrapper {
  border-radius: 10px;
}

.work-order-image-full {
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.trip-image {
  p {
    text-decoration: underline;
  }
}