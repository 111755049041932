.search-img-container {
  width: 100%;
  position: relative;

  .background-img {
    width: 100%;
    max-height: 310px;
    object-fit: cover;
    margin: auto;
    position: absolute;
    filter: brightness(50%);
    z-index: -1;
  }
}

.search-submit-button {
  --border-radius: 10px;
  --box-shadow: none;

  height: unset;
  margin: 0;
  margin-left: 8px;
}

.searchbar-search-icon.sc-ion-searchbar-md{
  top: 16px;
}