ion-button {
  margin: 36px 0;
  text-transform: capitalize;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: $semibold;
  --color: var(--ion-color-primary-contrast);

  &::part(native) {
    font-weight: bold;
  }

  &.in-progress {
    opacity: 1;
    --color: transparent;
  }

  ion-spinner {
    color: var(--ion-color-primary-contrast);
    height: 50%;
  }
}

// use with fill="clear" on IonButton
.link-button {
  display: flex;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  font-weight: bold;
  text-transform: none;
  letter-spacing: initial;
  --background-activated: transparent;
  --background-hover: transparent;
  --ripple-color: transparent;
  --padding-start: 0;
  --padding-end: 0;

  &:hover,
  &:focus {
    text-decoration: underline;
    opacity: 1;
  }

  &::part(native) {
    min-height: auto;
    height: auto;

    &::after {
      // with a fill, the specificity is broken
      // for the shadow class, so this is needed
      background: none;
    }
  }

  &.blue-link {
    color: $ion-color-blue;
    text-decoration: underline;
    margin-bottom: 10px;
  }
}
