.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.error-message {
  font-size: 0.8rem;
  display: block;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.mt-auto {
  margin-top: auto;
}

.m-auto {
  margin: auto;
}

.margin-top {
  margin-top: 40px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.align-center {
  align-items: center;
}

.box-shadow {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.padding-half {
  padding: 9px;
}

.w-100p {
  width: 100%;
}

.h-100p {
  height: 100% !important;
}

.text-align-center {
  text-align: center;
}
