.tss-logo-img-container {
  padding-top: 20px;
  width: 100%;
  position: relative;
  .background-img {
    width: 100%;
    margin: auto;
    position: absolute;
  }
}

.login-content {
  padding: 30px;
  form {
    .input-container {
      margin: 20px 0px;
      .text-input {
        border-bottom: 1px solid gray;
        margin-top: 10px;
      }
    }
    .button-block {
      margin-top: 30px;
    }
  }
}
