.content-container {
  .content-container-internal {
    .showpage-title {
      h1 {
        font-size: 28px;
        padding-top: 34px;
      }
    }

    .ion-padding {
      margin: 28px 0px 0px 0px;
      padding: 30px 20px;
    }

    .flex {
      .button-primary {
        --border-radius: 10px;
      }
      ion-button {
        &::part(native) {
          height: 52px !important;
        }
      }
    }
  }
}
