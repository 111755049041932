// Typography Variables
// ========================================

// Font Families
@font-face {
  font-display: fallback;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/lato-v24-latin-300.woff2') format('woff2');
}

@font-face {
  font-display: fallback;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/lato-v24-latin-regular.woff2') format('woff2');
}

@font-face {
  font-display: fallback;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/lato-v24-latin-700.woff2') format('woff2');
}

$primary-sans-serif: 'Lato', sans-serif;
$secondary-sans-serif: 'Lato', sans-serif;

// Sizes
$font-size-base: 14px;

$s-giga: 50px;
$s-mega: 42px;
$s-huge: 36px;
$s-larger: 32px;
$s-large: 24px;
$s-bigger: 20px;
$s-big: 18px;
$s-medium: 16px;
$s-base: 14px;
$s-small: 12px;
$s-tiny: 10px;

// Line Heights
$base-line-height: $font-size-base * 1.9;
$base-line-loose: $font-size-base * 1.4;
$base-line-small: $font-size-base;

// Font Weights
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
