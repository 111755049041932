.header-logo {
  height: 43px;
  display: block;
  margin: auto;
}

ion-header.header-container {
  background: #fff;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
}

.header-toolbar {
  --min-height: 70px;
  --padding-top: 14px;
  --padding-bottom: 13px;
  --padding-start: 22px;
  --padding-end: 8px;
}

// back button header section
.back-button-container {
  width: 100%;
  background-color: #f0f0f0;

  ion-button {
    margin: 8px;
    --box-shadow: none;
  }
}
