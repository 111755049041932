// Settings
@import 'settings/settings';

// Base Styles
@import 'base/typography';

// variables
@import 'settings/variables/typography';
@import 'settings/variables/colors';

// components
@import 'components/accordion';
@import 'components/buttons';
@import 'components/convenience';
@import 'components/file-input';
@import 'components/header';
@import 'components/input';
@import 'components/status_tile';
@import 'components/util';
@import 'components/visually-hidden';

// pages
@import 'views/login';
@import 'views/search';
@import 'views/work-order-search';
@import 'views/work-order-show';
@import 'views/work-order-checkedin';
@import 'views/work-order-images';
@import 'views/trip-preview';
@import 'views/geo-docs';

// Layout: on large screen sizes, constrict page size and center content
@media only screen and (min-width: 680px) {
  .content-container {
    display: flex;
    align-items: center;
    width: 100%;
    .content-container-internal {
      max-width: 680px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

ion-content {
  .content-container {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 32px;
  }
}

.card-styling {
  border: 1px solid #dadada;
  border-radius: 10px;
  box-shadow: 1px 4px 10px 0px rgba(0, 0, 0, 0.1);
}

:root {
  --ion-font-family: 'Lato';

  --ion-color-primary: #3f4248;
  --ion-color-primary-rgb: 63, 66, 72;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: * |color_primary_shade| *;
  --ion-color-primary-shade-darker: * |color_primary_shade_darker| *;
  --ion-color-primary-tint: * |color_primary_tint| *;

  --ion-color-backsplash: * |color_backsplash| *;
  --ion-color-backsplash-tint: * |color_backsplash_tint| *;

  --ion-background-color: white;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-text-color: * |color_text| *;
  --ion-text-color-rgb: * |color_text_rgb| *;

  --ion-border-color: gray;
  --ion-border-color-rgb: 128, 128, 128;

  --ion-tab-bar-background: #ffffff;
  --ion-tab-bar-background-rgb: 255, 255, 255;

  --ion-toolbar-background: #ffffff;
  --ion-toolbar-background-rgb: 255, 255, 255;

  --ion-card-color: * |color_text| *;
  --ion-card-background: #ffffff;
  --ion-card-background-rgb: 255, 255, 255;

  --ion-item-background: transparent;

  // TSS-specifics -----
  // Colors
  --tss-green: hsla(122, 44%, 41%, 1);
  --tss-grey-dark: #3f4248;
  --tss-grey-light: hsla(0, 0%, 38%, 1);
  --tss-grey-lightest: hsla(0, 0%, 73%, 1);
  --tss-red: #e10600;
  --tss-red-dark: #a5231d;
  --tss-red-darker: #6b120e;
  --tss-text: hsla(23, 15%, 11%, 1);
  --tss-white: hsla(0, 0%, 96%, 1);

  // TSS Ionic Color Variables
  // TSS Blue
  --ion-color-blue: #0017e1;
  --ion-color-blue-rgb: 0, 23, 225;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255, 255, 255;
  --ion-color-blue-shade: #0014c6;
  --ion-color-blue-tint: #1a2ee4;

  // TSS Green
  --ion-color-tss-green: #169339;
  --ion-color-tss-green-rgb: 22, 147, 57;
  --ion-color-tss-green-contrast: #ffffff;
  --ion-color-tss-green-contrast-rgb: 255, 255, 255;
  --ion-color-tss-green-shade: #138132;
  --ion-color-tss-green-tint: #2d9e4d;

  // TSS Grey Dark
  --ion-color-tss-grey-dark: #3f4248;
  --ion-color-tss-grey-dark-rgb: 63, 66, 72;
  --ion-color-tss-grey-dark-contrast: #ffffff;
  --ion-color-tss-grey-dark-contrast-rgb: 255, 255, 255;
  --ion-color-tss-grey-dark-shade: #373a3f;
  --ion-color-tss-grey-dark-tint: #52555a;

  // TSS Orange
  --ion-color-tss-orange: #bf8110;
  --ion-color-tss-orange-rgb: 191, 129, 16;
  --ion-color-tss-orange-contrast: #000000;
  --ion-color-tss-orange-contrast-rgb: 0, 0, 0;
  --ion-color-tss-orange-shade: #a8720e;
  --ion-color-tss-orange-tint: #c58e28;

  // TSS Pink
  --ion-color-tss-pink: #3a6995;
  --ion-color-tss-pink-rgb: 58, 105, 149;
  --ion-color-tss-pink-contrast: #ffffff;
  --ion-color-tss-pink-contrast-rgb: 255, 255, 255;
  --ion-color-tss-pink-shade: #335c83;
  --ion-color-tss-pink-tint: #4e78a0;

  // TSS Red
  --ion-color-tss-red: #e10600;
  --ion-color-tss-red-rgb: 225, 6, 0;
  --ion-color-tss-red-contrast: #ffffff;
  --ion-color-tss-red-contrast-rgb: 255, 255, 255;
  --ion-color-tss-red-shade: #c60500;
  --ion-color-tss-red-tint: #e41f1a;

  // Fonts
  --tss-body-medium: 400 0.875rem 'Lato';
  --tss-label-medium: 600 1rem 'Lato';

  // Drop shadow
  --tss-drop-shadow: (1px 3px 10px 10px rgba(0, 0, 0, 0.25));
}

// TSS Ionic Color
.ion-color-tss-blue {
  --ion-color-base: var(--ion-color-blue);
  --ion-color-base-rgb: var(--ion-color-blue-rgb);
  --ion-color-contrast: var(--ion-color-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-shade);
  --ion-color-tint: var(--ion-color-blue-tint);
}

.ion-color-tss-green {
  --ion-color-base: var(--ion-color-tss-green);
  --ion-color-base-rgb: var(--ion-color-tss-green-rgb);
  --ion-color-contrast: var(--ion-color-tss-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tss-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-tss-green-shade);
  --ion-color-tint: var(--ion-color-tss-green-tint);
}

.ion-color-tss-grey-dark {
  --ion-color-base: var(--ion-color-tss-grey-dark);
  --ion-color-base-rgb: var(--ion-color-tss-grey-dark-rgb);
  --ion-color-contrast: var(--ion-color-tss-grey-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tss-grey-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-tss-grey-dark-shade);
  --ion-color-tint: var(--ion-color-tss-grey-dark-tint);
}

.ion-color-tss-orange {
  --ion-color-base: var(--ion-color-tss-orange);
  --ion-color-base-rgb: var(--ion-color-tss-orange-rgb);
  --ion-color-contrast: var(--ion-color-tss-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tss-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-tss-orange-shade);
  --ion-color-tint: var(--ion-color-tss-orange-tint);
}

.ion-color-tss-pink {
  --ion-color-base: var(--ion-color-tss-pink);
  --ion-color-base-rgb: var(--ion-color-tss-pink-rgb);
  --ion-color-contrast: var(--ion-color-tss-pink-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tss-pink-contrast-rgb);
  --ion-color-shade: var(--ion-color-tss-pink-shade);
  --ion-color-tint: var(--ion-color-tss-pink-tint);
}

.ion-color-tss-red {
  --ion-color-base: var(--ion-color-tss-red);
  --ion-color-base-rgb: var(--ion-color-tss-red-rgb);
  --ion-color-contrast: var(--ion-color-tss-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tss-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-tss-red-shade);
  --ion-color-tint: var(--ion-color-tss-red-tint);
}

// Input Styles
.text-input {
  border-bottom: 1px solid var(--ion-border-color);
  margin-top: 10px;
}

// HRs don't work in ionic unless you jump through some hoops
hr {
  width: 100%;
  background: var(--ion-border-color);
  margin: 0;
}

.icon-large {
  font-size: 20px;
}

fieldset {
  border: none;
}
